import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    {
      /* Need comment or html breaks */
    }
    <p style={{
      "textAlign": "center"
    }}>Welcome.</p>
    <h3>{`About`}</h3>
    <p>{`Little about me, I grew up in Waldwick, NJ and
graduated in 2019 from Northwestern University
with a degree in chemical engineering. Currently working
at Superpedestrian based in Boston, MA.`}</p>
    <p>{`Still thinking about what I want to use this site for...
maybe reflections and journaling.`}</p>
    <p>{`But right now, I have been obsessed with Apex Legends.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      